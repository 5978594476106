import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect, useDispatch } from 'react-redux';
import {
    reactSelectStyle,
    truncateString,
    stockClass,
} from './../../Utils/Common';
import {
    handleOnSubmit,
    handleSelectSupplier,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputCost,
    handleInputQty,
    handleSelectItem,
    handleSelectPaymentTerms,
    handleToggleEditRow,
    handleEditInputRow,
    setModal,
    getPOTemplates,
    handleUpdateRow,
    handleAttrSubmit,
    handleCheckVat,
    getPaymentTerms,
    getSupplier,
    getSinglePurchaseOrder,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleSelectPOTemplate,
    SearchSupplier,
    handleSelectPaymentType,
    PODatePicker,
    dynamicInputChange,
    add_items,
    change_item,
    handleTaxType,
    computationHandler,
    SupItemOnly,
    searchProduct,
    ShowComboBox,
    getSingleProduct,
    cancelPo,
} from '../../redux/actions/purchase_order/purchase_order.actions';
import { MiniSpinner } from '../layouts/Spinner';
import { Redirect } from 'react-router-dom';
import PurchaseOrderViewTable from './PurchaseOrder.view.table';
import ReactTooltip from 'react-tooltip';
import { defaultFormat } from './../../Utils/DateFormat';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import PurchaseOrderCustomerModal from './modals/PurchaseOrder.supplier.modal';
import PaymentTermsModal from './modals/PaymentTerms.modal';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';
import DynamicFont from 'react-dynamic-font';
import debounce from 'debounce-promise';
import { subscriberSetting } from '../../redux/actions/settings_app/setting_app.actions';
import moment from "moment";
function PurchaseOrderEdit({
    purchase_order: {
        single_purchase_order,
        loading,
        suppliers,
        payment_terms,
        isSearchable,
        itemSelected,
        items,
        edit_row_index,
        isDiscount,
        default_product_value,
        count_items,
        edit_purchase_order_items,
        edit_attr,
        main_page,
        main_url,
        default_supplier_value,
        suppliers_default,
        po_templates,
        po_default_template,
        payment_mode_options,
        supplier_modal,
        payment_terms_modal,
        inv_default_template,
        tax_types,
        supplier_item_only,
        btn_form,
    },
    handleSelectSupplier,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputCost,
    handleInputQty,
    handleSelectItem,
    handleSelectPOTemplate,
    handleOnSubmit,
    toggleEditBtn,
    handleToggleEditRow,
    handleEditInputRow,
    handleUpdateRow,
    handleSelectPaymentTerms,
    SearchSupplier,
    handleSelectPaymentType,
    handleAttrSubmit,
    handleCheckVat,
    getPaymentTerms,
    getSupplier,
    getSinglePurchaseOrder,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    match,
    PODatePicker,
    add_items,
    change_item,
    getPOTemplates,
    setModal,
    dynamicInputChange,
    block_route,
    blockRoute,
    handleTaxType,
    computationHandler,
    SupItemOnly,
    searchProduct,
    ShowComboBox,
    cancelPo,
    subscriberSetting,
}) {
    const history = useHistory();

    useEffect(() => {
        async function fetchAll() {
            Promise.all([
                getPOTemplates(),
                getPaymentTerms(),
                getSupplier(),
                subscriberSetting('purchase_order'),
                getSinglePurchaseOrder(match.params.id),
                blockRoute(false),
            ]);
        }

        fetchAll();
    }, []);

    // console.log("single_purchase_order", single_purchase_order);
    const { purchase_order_items, discount, discount_type, tax, tax_type } =
        single_purchase_order;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(purchase_order_items),
        discount,
        discount_type,
        tax,
        tax_type,
    ]);

    const [showArrowBox, setShowArrowBox] = useState({ show: false, index: null, position: { x: 0, y: 0 } });

    // console.log(showArrowBox)

    const dispatch = useDispatch();
    // Add the handleHoverProduct function
    const handleHoverProduct = (productId, index, e) => {
        dispatch(getSingleProduct(productId));
    
        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox({
            show: true,
            index,
            position: { x: e.clientX, y: e.clientY }
        });
    };
    
    const handleMouseMove = (e) => {
        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox((prev) => ({
            ...prev,
            position: { x: e.clientX, y: e.clientY }
        }));
    };

    const handleScroll = () => {
        // Update the position of the hover box based on scroll
        setShowArrowBox((prev) => ({
            ...prev,
            position: { ...prev.position, y: prev.position.y + window.scrollY }
        }));
    };

    useEffect(() => {
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Remove scroll event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
            console.log('01');
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
            // console.log('02');
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () => {
        window.history.pushState(null, null, window.location.pathname);
        console.log('03');
    };

    if (main_page) return <Redirect to={main_url} />;

    const customFilter = (option, searchText) => {
        try {
            if (
                option.data.label
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.barcode
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.sub_label
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.sku
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.product_unit.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.description
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.lot_no
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    };

    // old computation
    // const subTotalComputed = single_purchase_order.purchase_order_items.reduce((sub, item) => sub + parseFloat(item.amount ? item.amount : item.cost),0);
    // const discount = single_purchase_order.discount_type == 1 ? ((parseFloat(subTotalComputed) * single_purchase_order.discount) / 100) : single_purchase_order.discount;
    // const subTotalDiscounted = subTotalComputed - discount;
    // const totalPriceComputed = subTotalDiscounted + ((parseFloat(subTotalDiscounted) * single_purchase_order.vat) / 100) + single_purchase_order.other;

    // formatted price
    // const subTotal = NumberFormat(subTotalComputed.toFixed(2));
    // const total = NumberFormat(totalPriceComputed.toFixed(2));
    // const itemSelectedAmount = itemSelected.qty * itemSelected.cost;
    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div>{props.data.label} [{props.data.brand.name}]</div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
                {/* <div style={{ fontSize: 11 }}>{props.data.lot_no}</div> */}
            </components.Option>
        );
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        control: (provided) => ({
            ...provided,
        }),
        singleValue: (provided) => ({
            ...provided,
        }),
    };

    const status_lbl = (
        <span className={`lbl-status mr-1 lbl-${single_purchase_order.status}`}>
            {single_purchase_order.status}
        </span>
    );
    const payment_status =
        single_purchase_order.payment_mode &&
        single_purchase_order.payment_mode.value != '' ? (
            <span
                className={`lbl-status mr-1 lbl-payment-type lbl-${single_purchase_order.payment_mode.label}`}
            >
                {single_purchase_order.payment_mode.label}
            </span>
        ) : (
            ''
        );

    // console.log("single_purchase_order", single_purchase_order);
        // console.log("mini loading", loading)
    return (
        <BeforeUnloadComponent
            ignoreBeforeunloadDocument={true}
            blockRoute={block_route}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Purchases
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item">
                                            <Link
                                                to={main_url}
                                                className="text-white"
                                            >
                                                Purchase Order No.
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#" className="text-white">
                                                {
                                                    single_purchase_order.purchase_order_no
                                                }
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <ReactTooltip effect="solid" event="click" />
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header mb-0 pb-0">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left"></i>{' '}
                                                Back
                                            </Link>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body pb-10m">
                                                {loading ? (
                                                    <MiniSpinner />
                                                ) : single_purchase_order.status !=
                                                      'saved' &&
                                                  single_purchase_order.status !=
                                                      'approved' ? (
                                                    <form>
                                                        <ReactTooltip
                                                            effect="solid"
                                                            event="click"
                                                        />
                                                        <section className="f-regular">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-12 mb-2">
                                                                            {
                                                                                status_lbl
                                                                            }
                                                                            {
                                                                                payment_status
                                                                            }
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Template{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            po_templates
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectPOTemplate
                                                                                        }
                                                                                        value={
                                                                                            po_default_template
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4"></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Supplier{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>{' '}
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <div className="form-control f-regular">
                                                                                        {
                                                                                            default_supplier_value.label
                                                                                        }
                                                                                    </div>
                                                                                    {/* <AsyncSelect defaultValue={default_supplier_value} isLoading={loading} loadingMessage={() => 'searching...'} defaultOptions={suppliers_default} loadOptions={SearchSupplier} isSearchable={true} cacheOptions onChange={handleSelectSupplier} placeholder="Select Supplier..." required/> */}
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                        {/* <Show when="inv.purchases.suppliers.create">
                                                                                        <div className="a-tagicon icons-s" onClick={() => setModal('supplier_modal', true)}>
                                                                                            <i className="ft ft-plus-circle text-primary"></i>
                                                                                        </div>
                                                                                    </Show> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="form-check-label font-medium-2 text-bold-600 ml-1">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="switchery"
                                                                                        checked={
                                                                                            supplier_item_only
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            SupItemOnly(
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                    />{' '}
                                                                                    Only
                                                                                    Show
                                                                                    Items
                                                                                    by
                                                                                    Supplier
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4"></div>
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                PO
                                                                                Date
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <div className="date-picker-100">
                                                                                        <DatePicker
                                                                                            onChange={(
                                                                                                date
                                                                                            ) =>
                                                                                                PODatePicker(
                                                                                                    date
                                                                                                )
                                                                                            }
                                                                                            selected={
                                                                                                single_purchase_order.purchase_order_date
                                                                                            }
                                                                                            name="purchase_order_date"
                                                                                            className="form-control f-regular"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="">
                                                                                {single_purchase_order.supplier_details && (
                                                                                    <Fragment>
                                                                                        {/* <table className='table table-hover table-striped table-bordered table-responsive'> */}
                                                                                        <table className="table table-hover table-striped table-bordered ">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className="text-center td-16m">
                                                                                                        SKU
                                                                                                    </th>
                                                                                                    <th className="text-center">
                                                                                                        Product
                                                                                                    </th>
                                                                                                    <th className="text-center mw-6m">
                                                                                                        Qty
                                                                                                    </th>
                                                                                                    <th className="text-center">
                                                                                                        Unit
                                                                                                    </th>
                                                                                                    <th className="text-center mw-8m">
                                                                                                        Unit/Price
                                                                                                    </th>
                                                                                                    <th className="text-center">
                                                                                                        Amount
                                                                                                    </th>
                                                                                                    {single_purchase_order.status !=
                                                                                                        'saved' && (
                                                                                                        <th className="text-center mw-2m">
                                                                                                            Action
                                                                                                        </th>
                                                                                                    )}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {single_purchase_order.purchase_order_items.map(
                                                                                                    (
                                                                                                        item,
                                                                                                        index
                                                                                                    ) => (
                                                                                                        <Fragment
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                        >
                                                                                                            {' '}
                                                                                                            {/* This is the key part */}
                                                                                                            <tr
                                                                                                                className={stockClass(
                                                                                                                    item.status_code
                                                                                                                )}
                                                                                                            >
                                                                                                                {/* <tr
                                                                                                                key={index}
                                                                                                                className={stockClass(
                                                                                                                    item.status_code
                                                                                                                )}
                                                                                                                > */}
                                                                                                                <td className="">
                                                                                                                    <DynamicFont
                                                                                                                        content={
                                                                                                                            item.sku
                                                                                                                        }
                                                                                                                    />
                                                                                                                </td>
                                                                                                                <td className="min-wdth-300 max-wdth-200">
                                                                                                                    {
                                                                                                                        item._new ? (
                                                                                                                            item.selected ? (
                                                                                                                                <div>
                                                                                                                                    {/* <div className="f-right" onClick={() =>
                                                                                                                                        ShowComboBox(
                                                                                                                                            index
                                                                                                                                        )
                                                                                                                                    }>
                                                                                                                                        <i className='ft ft-chevron-down c-pointer'></i>
                                                                                                                                    </div> */}
                                                                                                                                     <Link
                                                                                                                                        to={`/inventory/view/${item._id}`}
                                                                                                                                        target="_blank"
                                                                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                                                                        onMouseEnter={(e) => handleHoverProduct(item._id, index, e)}
                                                                                                                                        onMouseMove={(e) => handleMouseMove(e)}
                                                                                                                                        onMouseLeave={() => setShowArrowBox({ show: false, index: null, position: { x: 0, y: 0 } })}
                                                                                                                                    >
                                                                                                                                        {item.product_name}
                                                                                                                                    </Link>

                                                                                                                                    {showArrowBox.show && showArrowBox.index === index && (
                                                                                                                                        <div className="arrow-box" style={{ top: `${showArrowBox.position.y}px`, left: `${showArrowBox.position.x}px`, position: 'fixed' }}>
                                                                                                                                            <p>Incoming: <span className='font-weight-bold'>{item.productDetails.incoming_orders}</span></p>
                                                                                                                                                <p>Current stock: <span className='font-weight-bold'>{item.productDetails.total_stocks}</span></p>
                                                                                                                                                <p>Last arrival: <span className='font-weight-bold'>{moment(item.productDetails.last_arrival).isValid() ? moment(item.productDetails.last_arrival).format("YYYY-MM-DD") : "N/A"}</span></p>
                                                                                                                                            </div>
                                                                                                                                        )}
                                                                                                                                    <div className="f-12 text-dark">
                                                                                                                                        {
                                                                                                                                            item.barcode
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="f-12 text-dark">
                                                                                                                                        {
                                                                                                                                            item.description
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="f-12 text-dark">
                                                                                                                                        {
                                                                                                                                            item.brand.name
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                // <Select filterOption={customFilter} styles={customStyles} components={{Option}} classNamePrefix="lp-copy-sel" options={item.items} onChange={(val) => change_item(val, index)} isSearchable={isSearchable} />
                                                                                                                                <AsyncSelect
                                                                                                                                    value={{
                                                                                                                                        label: item.product_name,
                                                                                                                                        value: item._id,
                                                                                                                                    }}
                                                                                                                                    isLoading={
                                                                                                                                        loading
                                                                                                                                    }
                                                                                                                                    components={{
                                                                                                                                        Option,
                                                                                                                                    }}
                                                                                                                                    classNamePrefix="lp-copy-sel"
                                                                                                                                    loadingMessage={() =>
                                                                                                                                        'searching...'
                                                                                                                                    }
                                                                                                                                    noOptionsMessage={({ inputValue }) =>
                                                                                                                                    inputValue ? 'No Products' : 'Search product'
                                                                                                                                    }
                                                                                                                                    defaultOptions={
                                                                                                                                        item.items
                                                                                                                                    }
                                                                                                                                    loadOptions={debounce(
                                                                                                                                        (
                                                                                                                                            value
                                                                                                                                        ) =>
                                                                                                                                            searchProduct(
                                                                                                                                                value,
                                                                                                                                                index
                                                                                                                                            ),
                                                                                                                                            1000
                                                                                                                                    )}
                                                                                                                                    isSearchable={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    onChange={(
                                                                                                                                        val
                                                                                                                                    ) =>
                                                                                                                                        change_item(
                                                                                                                                            val,
                                                                                                                                            index
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    placeholder="Select Products..."
                                                                                                                                    required
                                                                                                                                />
                                                                                                                            )
                                                                                                                        ) : (
                                                                                                                            <div>
                                                                                                                                <div className="mw-90">
                                                                                                                                <Link
                                                                                                                                    to={`/inventory/view/${item._id}`}
                                                                                                                                    target="_blank"
                                                                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                                                                    onMouseEnter={(e) => handleHoverProduct(item._id, index, e)}
                                                                                                                                    onMouseMove={(e) => handleMouseMove(e)}
                                                                                                                                    onMouseLeave={() => setShowArrowBox({ show: false, index: null, position: { x: 0, y: 0 } })}
                                                                                                                                >
                                                                                                                                    {item.product_name}
                                                                                                                                </Link>

                                                                                                                                {showArrowBox.show && showArrowBox.index === index && (
                                                                                                                                    <div className="arrow-box" style={{ top: `${showArrowBox.position.y}px`, left: `${showArrowBox.position.x}px`, position: 'fixed' }}>
                                                                                                                                        <p>Incoming: <span className='font-weight-bold'>{item.productDetails.incoming_orders}</span></p>
                                                                                                                                            <p>Current stock: <span className='font-weight-bold'>{item.productDetails.total_stocks}</span></p>
                                                                                                                                            <p>Last arrival: <span className='font-weight-bold'>{moment(item.productDetails.last_arrival).isValid() ? moment(item.productDetails.last_arrival).format("YYYY-MM-DD") : "N/A"}</span></p>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                                {item.product ? (
                                                                                                                                    <>
                                                                                                                                        <div className="f-12 text-dark">
                                                                                                                                            {
                                                                                                                                                item
                                                                                                                                                    .product
                                                                                                                                                    .barcode
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        <div className="f-12 text-dark">
                                                                                                                                            {
                                                                                                                                                item
                                                                                                                                                    .product
                                                                                                                                                    .description
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    <>

                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                                {item.brand && (
                                                                                                                                    <div className="f-12 text-dark">
                                                                                                                                        {
                                                                                                                                            item
                                                                                                                                                .brand
                                                                                                                                                .name
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        // <span>{item.product_name} <br/> <span className="text-secondary f-10">{item.product != null && item.product.description}</span></span>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="mw-4m text-center">
                                                                                                                    <input
                                                                                                                        type="number"
                                                                                                                        className="form-control text-right"
                                                                                                                        step="any"
                                                                                                                        value={
                                                                                                                            item.qty
                                                                                                                        }
                                                                                                                        name="qty"
                                                                                                                        onChange={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            dynamicInputChange(
                                                                                                                                e,
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                </td>
                                                                                                                <td className="min-wdth-75 max-wdth-75 text-center">
                                                                                                                    {
                                                                                                                        item.product_unit_name
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    className="mw-8m"
                                                                                                                    align="right"
                                                                                                                >
                                                                                                                    <input
                                                                                                                        type="number"
                                                                                                                        className="form-control text-right"
                                                                                                                        step="any"
                                                                                                                        value={
                                                                                                                            item.cost
                                                                                                                        }
                                                                                                                        name="cost"
                                                                                                                        onChange={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            dynamicInputChange(
                                                                                                                                e,
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                </td>
                                                                                                                <td align="right">
                                                                                                                    {item.amount
                                                                                                                        ? NumberFormat(
                                                                                                                              item.amount.toFixed(
                                                                                                                                  2
                                                                                                                              )
                                                                                                                          )
                                                                                                                        : NumberFormat(
                                                                                                                              item.cost.toFixed(
                                                                                                                                  2
                                                                                                                              )
                                                                                                                          )}
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    align="center"
                                                                                                                    className="mw-6m"
                                                                                                                >
                                                                                                                    <span className="m-1">
                                                                                                                        <button
                                                                                                                            className="btn btn-sm btn-primary"
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                handleDiscountItem(
                                                                                                                                    index,
                                                                                                                                    false
                                                                                                                                )
                                                                                                                            }
                                                                                                                            type="button"
                                                                                                                        >
                                                                                                                            <i className="ft ft-tag"></i>
                                                                                                                        </button>
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        <button
                                                                                                                            className="btn btn-sm btn-danger"
                                                                                                                            type="button"
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                handleRemoveItem(
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <i className="ft ft-trash"></i>
                                                                                                                        </button>
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            {(item.discount_type &&
                                                                                                                item
                                                                                                                    .discount_type
                                                                                                                    .value !==
                                                                                                                    'none') ||
                                                                                                            item.showDiscountRow ? (
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        colSpan={
                                                                                                                            1
                                                                                                                        }
                                                                                                                    ></td>
                                                                                                                    <td
                                                                                                                        className="d-flex ali justify-content-center align-items-center"
                                                                                                                        style={{
                                                                                                                            gap: '5%',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span>
                                                                                                                            Discount:
                                                                                                                        </span>

                                                                                                                        <span className="w-20 table-data__discount">
                                                                                                                            {(() => {
                                                                                                                                switch (
                                                                                                                                    item
                                                                                                                                        .discount_type
                                                                                                                                        .value
                                                                                                                                ) {
                                                                                                                                    case 'percent':
                                                                                                                                        return (
                                                                                                                                            <div className="input-group">
                                                                                                                                                <input
                                                                                                                                                    onChange={(
                                                                                                                                                        e
                                                                                                                                                    ) =>
                                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                                            e,
                                                                                                                                                            index
                                                                                                                                                        )
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        item.discount
                                                                                                                                                    }
                                                                                                                                                    name="discount"
                                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                                    type="number"
                                                                                                                                                />
                                                                                                                                                <div className="input-group-append">
                                                                                                                                                    <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                                        %
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        );
                                                                                                                                    case 'amount':
                                                                                                                                        return (
                                                                                                                                            <input
                                                                                                                                                onChange={(
                                                                                                                                                    e
                                                                                                                                                ) =>
                                                                                                                                                    handleInputDiscountProduct(
                                                                                                                                                        e,
                                                                                                                                                        index
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                value={
                                                                                                                                                    item.discount
                                                                                                                                                }
                                                                                                                                                name="discount"
                                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                                type="number"
                                                                                                                                            />
                                                                                                                                        );
                                                                                                                                    default: // 0 disabled
                                                                                                                                        return (
                                                                                                                                            <input
                                                                                                                                                value={
                                                                                                                                                    item.discount
                                                                                                                                                }
                                                                                                                                                disabled
                                                                                                                                                name="discount"
                                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                                type="number"
                                                                                                                                            />
                                                                                                                                        );
                                                                                                                                }
                                                                                                                            })()}
                                                                                                                        </span>

                                                                                                                        <span className="table-data__discount-dropdown">
                                                                                                                            <Select
                                                                                                                                name="discount_type"
                                                                                                                                onChange={(
                                                                                                                                    selectOption
                                                                                                                                ) =>
                                                                                                                                    handleSelectDiscountTypeProduct(
                                                                                                                                        selectOption,
                                                                                                                                        index
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                menuPortalTarget={
                                                                                                                                    document.body
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    item.discount_type
                                                                                                                                }
                                                                                                                                defaultValue={
                                                                                                                                    item.discount_type
                                                                                                                                }
                                                                                                                                label="Single select"
                                                                                                                                options={
                                                                                                                                    percent_value
                                                                                                                                }
                                                                                                                                isSearchable={
                                                                                                                                    false
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                    <td></td>
                                                                                                                    <td></td>
                                                                                                                    <td></td>
                                                                                                                    <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                                        -{' '}
                                                                                                                        {NumberFormat(
                                                                                                                            Number(
                                                                                                                                item.discount_amount
                                                                                                                            ).toFixed(
                                                                                                                                2
                                                                                                                            )
                                                                                                                        )}
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        align="center"
                                                                                                                        className="mw-2m"
                                                                                                                    >
                                                                                                                        <button
                                                                                                                            className="btn btn-sm btn-danger"
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                handleDiscountItem(
                                                                                                                                    index,
                                                                                                                                    true
                                                                                                                                )
                                                                                                                            }
                                                                                                                            type="button"
                                                                                                                        >
                                                                                                                            <i className="ft ft-trash"></i>
                                                                                                                        </button>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            ) : null}
                                                                                                        </Fragment>
                                                                                                    )
                                                                                                )}
                                                                                                {/* <tr classNameName="bg-primary bg-accent-2">
                                                                                            <td className='min-wdth-100 max-wdth-100'>{itemSelected.sku} </td>
                                                                                            <td className='min-wdth-300 max-wdth-200'><Select filterOption={customFilter} components={{Option}} classNamePrefix="lp-copy-sel" isSearchable={isSearchable} value={default_product_value} onChange={handleSelectItem} options={items} /></td>
                                                                                            <td className='min-wdth-120 max-wdth-100'><input onChange={handleInputQty} value={itemSelected.qty} className="form-control" type="text" name="qty" min={1} step="any"/></td>
                                                                                            <td className='min-wdth-75 max-wdth-75 text-center'>{itemSelected.product_unit_name}</td>
                                                                                            <td className='min-wdth-150 max-wdth-150'><input onChange={handleInputCost} value={itemSelected.cost} type="number" name="cost" min={1} className="form-control"/></td>
                                                                                            <td className='min-wdth-100 max-wdth-100' align="right"><div className="td-padding">{itemSelectedAmount ?  NumberFormat(itemSelectedAmount.toFixed(2)) : 0}</div></td>
                                                                                            <td className="text-center" width="10%"><a className="btn btn-sm btn-primary text-white" onClick={handleAddItem}>add</a></td>
                                                                                        </tr> */}
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan="7"
                                                                                                        className="text-left"
                                                                                                    >
                                                                                                        <button
                                                                                                            className="btn btn-primary"
                                                                                                            type="button"
                                                                                                            onClick={
                                                                                                                add_items
                                                                                                            }
                                                                                                        >
                                                                                                            <i className="la la-plus"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="p-2">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan={
                                                                                                            4
                                                                                                        }
                                                                                                    ></td>
                                                                                                    <td>
                                                                                                        <div align="right">
                                                                                                            Sub
                                                                                                            Total
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td align="right">
                                                                                                        {NumberFormat(
                                                                                                            single_purchase_order.sub_total.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>
                                                                                                    {single_purchase_order.status !=
                                                                                                        'saved' && (
                                                                                                        <td></td>
                                                                                                    )}
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan={
                                                                                                            4
                                                                                                        }
                                                                                                    ></td>
                                                                                                    <td>
                                                                                                        <div align="right">
                                                                                                            Discount
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        width="12%"
                                                                                                        className="min-width-150"
                                                                                                    >
                                                                                                        {(() => {
                                                                                                            switch (
                                                                                                                single_purchase_order
                                                                                                                    .discount_type
                                                                                                                    .value
                                                                                                            ) {
                                                                                                                case 'percent':
                                                                                                                    return (
                                                                                                                        <div className="input-group">
                                                                                                                            <input
                                                                                                                                onChange={
                                                                                                                                    handleInputChangeDiscount
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    single_purchase_order.discount
                                                                                                                                }
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                            <div className="input-group-append">
                                                                                                                                <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                    %
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                case 'amount':
                                                                                                                    return (
                                                                                                                        <input
                                                                                                                            onChange={
                                                                                                                                handleInputChangeDiscount
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                single_purchase_order.discount
                                                                                                                            }
                                                                                                                            name="discount"
                                                                                                                            className="form-control text-right f-regular"
                                                                                                                            type="number"
                                                                                                                        />
                                                                                                                    );
                                                                                                                default: // 0 disabled
                                                                                                                    return (
                                                                                                                        <input
                                                                                                                            value={
                                                                                                                                single_purchase_order.discount
                                                                                                                            }
                                                                                                                            disabled
                                                                                                                            name="discount"
                                                                                                                            className="form-control text-right f-regular"
                                                                                                                            type="number"
                                                                                                                        />
                                                                                                                    );
                                                                                                            }
                                                                                                        })()}
                                                                                                    </td>
                                                                                                    <td className="text-center padding-td-5">
                                                                                                        <Select
                                                                                                            name="discount_type"
                                                                                                            onChange={
                                                                                                                handleSelectDiscountType
                                                                                                            }
                                                                                                            menuPortalTarget={
                                                                                                                document.body
                                                                                                            }
                                                                                                            value={
                                                                                                                single_purchase_order.discount_type
                                                                                                            }
                                                                                                            defaultValue={
                                                                                                                single_purchase_order.discount_type
                                                                                                            }
                                                                                                            label="Single select"
                                                                                                            options={
                                                                                                                percent_value
                                                                                                            }
                                                                                                            isSearchable={
                                                                                                                false
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan={
                                                                                                            4
                                                                                                        }
                                                                                                    ></td>
                                                                                                    <td>
                                                                                                        <div align="right">
                                                                                                            VAT
                                                                                                            (
                                                                                                            {
                                                                                                                single_purchase_order.tax
                                                                                                            }{' '}
                                                                                                            %)
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td align="right">
                                                                                                        {NumberFormat(
                                                                                                            single_purchase_order.vat_amount.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td className="padding-td-5 min-width-150">
                                                                                                        <Select
                                                                                                            name="tax_type"
                                                                                                            onChange={
                                                                                                                handleTaxType
                                                                                                            }
                                                                                                            value={
                                                                                                                single_purchase_order.tax_type
                                                                                                            }
                                                                                                            menuPortalTarget={
                                                                                                                document.body
                                                                                                            }
                                                                                                            options={
                                                                                                                tax_types
                                                                                                            }
                                                                                                            isSearchable={
                                                                                                                false
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan="4"></td>
                                                                                                    <td>
                                                                                                        <div align="right">
                                                                                                            <strong>
                                                                                                                Total
                                                                                                            </strong>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td align="right">
                                                                                                        {NumberFormat(
                                                                                                            single_purchase_order.total.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan="20"></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan="20"></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>

                                                                                        <div className="col-12 mb-2"></div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-4">
                                                                                                <label>
                                                                                                    Remarks{' '}
                                                                                                </label>
                                                                                                <div className="form-group row">
                                                                                                    <div className="col-md-10 col-10 pr-0">
                                                                                                        <textarea
                                                                                                            onChange={
                                                                                                                handleInputChange
                                                                                                            }
                                                                                                            value={
                                                                                                                single_purchase_order.remarks
                                                                                                                    ? single_purchase_order.remarks
                                                                                                                    : ''
                                                                                                            }
                                                                                                            placeholder="Remarks"
                                                                                                            rows="2"
                                                                                                            name="remarks"
                                                                                                            className="form-control f-regular"
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                                                        <div className="input-group-prepend">
                                                                                                            <span
                                                                                                                href="#"
                                                                                                                className="tool-tip text-primary"
                                                                                                                data-tip="this is a tool tip"
                                                                                                            >
                                                                                                                <i className="la la-question-circle"></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <label>
                                                                                                    Payment
                                                                                                    Mode
                                                                                                </label>
                                                                                                <div className="form-group row">
                                                                                                    <div className="col-md-10 col-10 pr-0">
                                                                                                        <Select
                                                                                                            options={
                                                                                                                payment_mode_options
                                                                                                            }
                                                                                                            onChange={
                                                                                                                handleSelectPaymentType
                                                                                                            }
                                                                                                            value={
                                                                                                                single_purchase_order.payment_mode
                                                                                                            }
                                                                                                            isSearchable={
                                                                                                                isSearchable
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                                                        <div className="input-group-prepend">
                                                                                                            <span
                                                                                                                href="#"
                                                                                                                className="tool-tip text-primary"
                                                                                                                data-tip="this is a tool tip"
                                                                                                            >
                                                                                                                <i className="la la-question-circle"></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-4">
                                                                                                <label>
                                                                                                    Payment
                                                                                                    Terms{' '}
                                                                                                </label>
                                                                                                <div className="form-group row">
                                                                                                    <div className="col-md-10 col-10 pr-0">
                                                                                                        <Select
                                                                                                            options={
                                                                                                                payment_terms
                                                                                                            }
                                                                                                            onChange={
                                                                                                                handleSelectPaymentTerms
                                                                                                            }
                                                                                                            value={
                                                                                                                single_purchase_order.payment_terms
                                                                                                            }
                                                                                                            isSearchable={
                                                                                                                isSearchable
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                                                        <div className="input-group-prepend">
                                                                                                            <span
                                                                                                                href="#"
                                                                                                                className="tool-tip text-primary"
                                                                                                                data-tip="this is a tool tip"
                                                                                                            >
                                                                                                                <i className="la la-question-circle"></i>
                                                                                                            </span>
                                                                                                            <div
                                                                                                                className="a-tagicon icons-s"
                                                                                                                onClick={() =>
                                                                                                                    setModal(
                                                                                                                        'payment_terms_modal',
                                                                                                                        true
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Fragment>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </form>
                                                ) : (
                                                    <PurchaseOrderViewTable />
                                                )}
                                                <div className="row justify-content-end mt-3">
                                                    <div className="mr-2">
                                                        <div className="form-group">
                                                            {single_purchase_order.status !=
                                                                'saved' &&
                                                                single_purchase_order.status !=
                                                                    'approved' &&
                                                                single_purchase_order.status !=
                                                                    'cancelled' && (
                                                                    <Fragment>
                                                                        <Show when="inv.purchases.purchase-orders.draft">
                                                                            <div className="btn-group btn-dpd">
                                                                                <button
                                                                                    className="btn btn-default btn-outline-light text-dark btn-main"
                                                                                    onClick={() =>
                                                                                        handleOnSubmit(
                                                                                            'update',
                                                                                            'draft'
                                                                                        )
                                                                                    }
                                                                                    type="button"
                                                                                    disabled={loading}
                                                                                >
                                                                                    Save
                                                                                    as
                                                                                    Draft
                                                                                </button>
                                                                                <button
                                                                                    data-toggle="dropdown"
                                                                                    className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                                    type="button"
                                                                                    disabled={loading}
                                                                                >
                                                                                    <span className="caret"></span>
                                                                                </button>
                                                                                <ul className="dropdown-menu">
                                                                                    {/* <li><a className="dpd-menu" onClick={() => handleOnSubmit('update', 'draft')}>Daft</a></li> */}
                                                                                    <li>
                                                                                        <a
                                                                                            className="dpd-menu"
                                                                                            onClick={() =>
                                                                                                handleOnSubmit(
                                                                                                    'update',
                                                                                                    'draft-email'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Draft
                                                                                            &
                                                                                            Email
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a
                                                                                            className="dpd-menu"
                                                                                            onClick={() =>
                                                                                                handleOnSubmit(
                                                                                                    'update',
                                                                                                    'draft-print'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Draft
                                                                                            &
                                                                                            Print
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </Show>{' '}
                                                                        <div className="btn-group btn-dpd">
                                                                            <button
                                                                                className="btn btn-primary  btn-main"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'update',
                                                                                        'saved'
                                                                                    )
                                                                                }
                                                                                type="button"
                                                                                disabled={loading}
                                                                            >
                                                                                Save
                                                                                Purchase
                                                                            </button>
                                                                            <button
                                                                                data-toggle="dropdown"
                                                                                className="btn btn-primary dropdown-toggle btn-carret"
                                                                                type="button"
                                                                                disabled={loading}
                                                                            >
                                                                                <span className="caret"></span>
                                                                            </button>
                                                                            <ul className="dropdown-menu">
                                                                                {/* {
                                                                            (edit_row_index != null || isDiscount) &&
                                                                            <li><a className="dpd-menu" href="#" onClick={() => handleOnSubmit('update', 'saved')}>Save</a></li>
                                                                        } */}
                                                                                <li>
                                                                                    <a
                                                                                        className="dpd-menu"
                                                                                        onClick={() =>
                                                                                            handleOnSubmit(
                                                                                                'update',
                                                                                                'saved-email'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                        &
                                                                                        Email
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a
                                                                                        className="dpd-menu"
                                                                                        onClick={() =>
                                                                                            handleOnSubmit(
                                                                                                'update',
                                                                                                'saved-print'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                        &
                                                                                        Print
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Fragment>
                                                                )}
                                                            {(single_purchase_order.status ==
                                                                'saved' ||
                                                                single_purchase_order.status ==
                                                                    'approved') &&
                                                                single_purchase_order.status !=
                                                                    'cancelled' && (
                                                                    <Fragment>
                                                                        <Show when="inv.purchases.purchase-orders.cancel">
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                onClick={
                                                                                    cancelPo
                                                                                }
                                                                                type="button"
                                                                            >
                                                                                {btn_form.cancel ? (
                                                                                    <i className="icon-loading-custom"></i>
                                                                                ) : (
                                                                                    <span>
                                                                                        Cancel
                                                                                    </span>
                                                                                )}
                                                                            </button>
                                                                        </Show>
                                                                    </Fragment>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <PurchaseOrderCustomerModal
                    show={supplier_modal}
                    onHide={() => setModal('supplier_modal', false)}
                />
                <PaymentTermsModal
                    show={payment_terms_modal}
                    onHide={() => setModal('payment_terms_modal', false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    purchase_order: state.purchase_order,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    blockRoute,
    handleOnSubmit,
    toggleEditBtn,
    handleSelectSupplier,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputCost,
    handleInputQty,
    handleSelectItem,
    handleSelectPaymentTerms,
    handleSelectPaymentType,
    handleToggleEditRow,
    handleEditInputRow,
    handleUpdateRow,
    handleAttrSubmit,
    handleCheckVat,
    getPaymentTerms,
    getSupplier,
    setModal,
    PODatePicker,
    getSinglePurchaseOrder,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleSelectPOTemplate,
    SearchSupplier,
    getPOTemplates,
    dynamicInputChange,
    add_items,
    change_item,
    handleTaxType,
    computationHandler,
    SupItemOnly,
    searchProduct,
    ShowComboBox,
    cancelPo,
    subscriberSetting,
    getSingleProduct,
})(PurchaseOrderEdit);
