import {
    SET_LOADING,
    SEARCH_INPUT,
    GET_PO,
    GET_PO_DATA,
    RECEIVING_INPUT_CHANGE,
    GET_RECEIVINGS,
    RECEIVING_DATA,
    GET_UNRECEIVE_DATA,
    LOAD_UNRECEIVE_DATA,
    SET_PO_ITEM_ID,
    BOOLEAN_VAR,
    GET_SUPPLIERS,
    HANDLE_PO_SELECT_SUPPLIER,
    GET_PO_SUPPLIERS_DEFAULT,
    RECEIVING_LOAD_ITEM_SUP,
    MANUAL_ITEM,
    SORT_BY_COLUMN,
    SET_MODAL,
    MANUAL,
    SET_PO,
    SUPPLIER_NAME,
    BTN_SUB,
    EDIT_COST,
    EDIT_SRP,
    RECEIVING_CLEAR_UPLOAD,
    SEND_EMAIL,
    RESEND_EMAIL,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    RECEIVING_REDIRECT,
    GET_RECEIVING_TEMPLATES,
    SELECT_RECEIVING_TEMPLATE,
    DYNAMIC_SEND_EMAIL,
    GET_IR_ROWS_TO_DELETE,
    CLEAR_DATA,
    BTN_FORM,
    RECEIVING_PRINT,
    HOVER_PRODUCT,
    GET_STATUS,
    GET_IR_STATUS,
} from '../../types';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
} from '../../../Services/_toast.service';
import $, { map } from 'jquery';
import { ReceivingService } from './_service.receiving';
import {
    SwalWarning,
    SwalError,
    SwalSuccessAutoRemove,
    SwallverfiyPass,
    SwalWarningHtml,
} from '../../../Services/_swal.service';
import { blockRoute } from '../helper/helper.actions';
import {
    getNumRows,
    reactSelectStyle,
    settoPrint,
    gettoPrint,
} from '../../../Utils/Common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { getUser } from '../../../Utils/Common';
import { getUserData } from '../../../Utils/Common';

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    dispatch(getReceiving(1));
};

export const handleInputChange = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: RECEIVING_INPUT_CHANGE,
        payload: { key: e.target.name, value: e.target.value },
    });
};

export const handleChangeStatusView =
    (status_view) => async (dispatch, getState) => {
        dispatch({ type: GET_STATUS, payload: status_view });

        let { search, receivings } = getState().receiving;

        if (
            typeof receivings[status_view] === 'undefined' ||
            receivings[status_view] == null
        ) {
            dispatch({ type: SEARCH_INPUT, payload: '' });
            dispatch(getReceiving(1));
        } else {
            dispatch({
                type: SEARCH_INPUT,
                payload: receivings[status_view].search,
            });
            dispatch({
                type: GET_RECEIVINGS,
                payload: {
                    receivings: receivings,
                    pagination: receivings[status_view],
                },
            });
        }
    };

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_LOADING, payload: status });
export const getUnreceive = (boolean) => async (dispatch) =>
    dispatch({ type: GET_UNRECEIVE_DATA, payload: boolean });

//  fetch brands
export const getReceiving =
    (pageNumber, rows_per_page = null, pageOrigin = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));
        let {
            search,
            pagination,
            pagination_list,
            status_view,
            item_id,
            sort_order_name,
            sort_order_direction,
        } = getState().receiving;
        let rows_per_page_val =
            rows_per_page != null ? rows_per_page : getNumRows('receiving');

        let params = {
            search: search,
            options: {
                page: pageNumber ? pageNumber : pagination.activePage,
                limit: rows_per_page_val ? rows_per_page_val : 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
            product_id: pageOrigin ? pageOrigin.product_id : null,
        };

        if (search == '' || search !== null) {
            ReceivingService.getReceiving(params, status_view)
                .then((res) => {
                    let rec_data = [];
                    res.data.docs.map((rec, key) => {
                        let temp_data = {
                            _id: rec._id,
                            item_receipt_no: rec.item_receipt_no,
                            reference_no: rec.reference_no,
                            reference_id: rec.reference_id,
                            supplier_name:
                                rec.supplier !== null ? rec.supplier.name : '',
                            supplier_id:
                                rec.supplier !== null ? rec.supplier._id : '',
                            created_at: rec.created_at,
                            delivery_date: rec.delivery_date,
                            delivery_status: rec.delivery_status,
                            _total_items: rec._total_items,
                            _total_qty: rec._total_qty,
                            remarks: rec.remarks,
                            status: rec.status,
                            uploads: rec.uploads,
                            email_sent: rec.email_sent,
                        };

                        rec_data.push(temp_data);
                    });

                    let data_list = [];
                    rec_data.map((data) => {
                        data_list.push(data);
                    });

                    pagination_list[status_view] = data_list;

                    // console.log("rec_data", rec_data);
                    dispatch({
                        type: GET_IR_STATUS,
                        payload: {
                            pagination_list: pagination_list,
                            pagination: {
                                totalCount: res.data.total_docs,
                                activePage: res.data.page,
                                itemsCountPerPage: res.data.limit,
                                totalItemsCount: res.data.total_docs,
                            },
                            receivings: rec_data,
                        },
                    });
                    dispatch(setLoading(false));
                    dispatch(receivingRedirect(false));
                })
                .catch((err) => {
                    dispatch(setLoading(false));
                    // ToastDanger('Network error, please reload the page.');
                    console.log(err);
                });
        }
    };

export const getPO = () => async (dispatch) => {
    ReceivingService.getPO()
        .then((res) => {
            var data = res.data.purchase_orders;
            var data_arr = [];

            data.map((data_po) =>
                data_arr.push({
                    value: data_po._id,
                    label: data_po.purchase_order_no,
                    name: 'po',
                })
            );

            return data_arr;
        })
        .then((data_po) => {
            dispatch({ type: GET_PO, payload: { po: data_po } });
        })
        .catch((err) => {
            // ToastDanger('Failed to load P.O.');
            console.log(err);
        });
};

export const getPoData = (id) => async (dispatch) => {
    if (id) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch(setLoading(true));
    ReceivingService.getPoData(id)
        .then((res) => {
            dispatch(setLoading(false));
            var po = res.data.purchase_order;
            var items = [];

            //console.log(' po.purchase_order_items',  po.purchase_order_items);
            po.purchase_order_items.map((data, index) => {
                console.log('data', data);
                items.push({
                    sku: data.product.sku,
                    product_name: data.product.product_name,
                    order_qty: data.qty,
                    cost: data.cost,
                    received_qty: 0,
                    srp: data.srp,
                    item_id: data.product._id.toString(),
                    lot_no: '',
                    expiry_date: '',
                    org_cost: data.cost,
                    org_srp: data.srp,
                    status_code: data.product.status_code,
                    description: data.product.description,
                    barcode: data.product.barcode,
                    product_unit_name: data.product_unit_name,
                    product_unit_id: data.product_unit_id,
                    brand: data.brand,
                    brand_id: data.brand_id,
                    category: data.category,
                    category_id: data.category_id,
                    productDetails: [],
                });
            });

            dispatch({
                type: GET_PO_DATA,
                payload: {
                    PO: po,
                    supplier_name: po.supplier_details.name,
                    po_items: items,
                    remarks: po.remarks,
                },
            });
        })
        .catch((err) => {
            // ToastDanger('Failed to load P.O.');
            dispatch(setLoading(false));
            console.log(err);
        });
};

export const submitReceiving = (formdata) => async (dispatch, getState) => {
    const { item_receipt_template_id, supplier_name } =
        getState().receiving.receivingData;
    const { manual, supplier_id } = getState().receiving.single_receiving;
    const { po_items } = getState().receiving;

    let errorFound = false;

    // console.log(formdata);

    if (formdata.purchase_order_id === undefined) {
        ToastDanger('P.O number required.');
        errorFound = true;
    } else if (formdata.delivery_date == null) {
        ToastDanger('Delivery date required.');
        errorFound = true;
    } else if (manual && supplier_id == null) {
        ToastDanger('Please Select a Supplier');
        errorFound = true;
    }
    // else if (manual && formdata.reference_no == '') {
    //     ToastDanger('Reference No is required.');
    //     errorFound = true;
    // }
    else {
        po_items.forEach((item) => {
            if (errorFound) return;
            if (item.product_name == '') {
                ToastDanger('Please add Product item at least one.');
                errorFound = true;
            } else if (item.cost <= 0) {
                ToastDanger(
                    `<strong>${item.product_name}</strong> cost cannot be 0`
                );
                errorFound = true;
            } else if (item.order_qty <= 0) {
                ToastDanger(
                    'Ordered Quantity cannot be zero value, please include the ordered quantity'
                );
                errorFound = true;
            }
        });
    }

    if (!errorFound) {
    dispatch(setLoading(true));
        formdata['item_receipt_template_id'] = item_receipt_template_id;
        // console.log(formdata);
        ReceivingService.submitReceiving(formdata)
            .then((res) => {
                if (res.data.status == 0) {
                    ToastDanger(res.data.errors);
                } else if (res.data.status == 1) {
                    ToastSuccess(res.data.message);
                    dispatch({ type: MANUAL, payload: [] });
                    dispatch({ type: GET_PO, payload: [] });
                    dispatch({
                        type: RECEIVING_INPUT_CHANGE,
                        payload: { reference_no: '', remarks: '' },
                    });

                    // console.log("formdata.actions", formdata.actions);
                    if (formdata.actions == 'print') {
                        settoPrint(true, res.data.id, 'Recieving');
                        // dispatch(setModal('print_modal', res.data.id));
                        // dispatch({type : RECEIVING_PRINT, payload : {print : true, id : res.data.id}})
                    }
                    dispatch(receivingRedirect(true));
                }
            })
            .catch((err) => {
                if (err.data.errors.length > 0) {
                    ToastDanger(err.data.errors);
                } else {
                    ToastDanger('Failed to Saved');
                }
            });
    }
};

export const viewReceiving = (id, callback) => async (dispatch) => {
    dispatch(setLoading(true));
    ReceivingService.viewReceiving(id)
        .then((res) => {
            var receiving = {};
            var item_data = [];
            let data_rec = res.data;

            const singleReceiving = res.data.receiving;
            let subs_setting = res.data.subs_setting;

            singleReceiving.delivery_receive_items.map((data, index) => {
                var date = data.expiry_date;
                if (date) {
                    date = new Date(data.expiry_date);
                }
                item_data.push({
                    _id: data.product_id || data.item_id,
                    sku: data.sku,
                    product_name: data.product_name,
                    order_qty: data.order_qty,
                    product_unit_id: data.product_unit_id,
                    product_unit: data.product_unit,
                    cost: data.cost,
                    received_qty: data.received_qty,
                    srp: data.srp,
                    item_id: data.product_id || data.item_id,
                    lot_no: data.lot_no,
                    expiry_date: date,
                    date_str: date ? moment(date).format('d/DD/YYYY') : '',
                    old_srp: data.old_srp,
                    old_cost: data.old_cost,
                    org_srp: data.srp,
                    org_cost: data.cost,
                    description: data.description ? data.description : '',
                    status_code: data.status_code,
                    barcode: data.barcode,
                    barcode_qty: data.received_qty,
                    brand_id: data.brand_id,
                    brand: data.brand,
                    category_id: data.category_id,
                    category: data.category,
                    productDetails: [],
                });
            });

            // item_data.forEach(item => console.log(item.status_code))
            receiving._id = singleReceiving._id;
            receiving.item_receipt_no = singleReceiving.item_receipt_no;
            receiving.reference_no = singleReceiving.reference_no;
            receiving.supplier_id = singleReceiving.supplier_id;
            receiving.supplier_name = singleReceiving.supplier
                ? singleReceiving.supplier.name
                : '';
            receiving.supplier = singleReceiving.supplier;
            receiving.purchase_order_id = singleReceiving.purchase_order_id;
            receiving.item_receipt_template_id =
                singleReceiving.item_receipt_template_id;
            receiving.remarks = singleReceiving.remarks;
            receiving.status = singleReceiving.status;
            receiving.po_no = singleReceiving.po
                ? singleReceiving.po.po_no
                : '';
            receiving.date = singleReceiving.date;
            receiving.users = singleReceiving.users;
            receiving.user_approved_by = singleReceiving.user_approved_by;
            receiving.manual = singleReceiving.is_manual
                ? singleReceiving.is_manual
                : false;
            receiving.delivery_status = singleReceiving.delivery_status;
            receiving.latest_lot_no = singleReceiving.latest_lot_no;
            receiving.oldest_lot_no = singleReceiving.oldest_lot_no;
            receiving.latest_ir_no = singleReceiving.latest_ir_no;
            receiving.oldest_ir_no = singleReceiving.oldest_ir_no;
            receiving.uploads = singleReceiving.uploads;
            receiving.logo = singleReceiving.logo;
            receiving.template_setting = singleReceiving.template_setting;

            let ir_default_template = {
                value: receiving.template_setting._id,
                label: receiving.template_setting.template_name,
            };

            // console.log(receiving.template_setting)

            // console.log("item_data", item_data)
            dispatch({
                type: RECEIVING_DATA,
                payload: {
                    receivingData: receiving,
                    po_items: item_data,
                    single_receiving: {
                        reference_no: receiving.reference_no,
                        remarks: receiving.remarks,
                    },
                    subs_setting: subs_setting,
                    deliveryDate: new Date(data_rec.date),
                    ir_default_template,
                },
            });
            callback(new Date(data_rec.date), item_data);
            // console.log("item_data", item_data);
            dispatch(setLoading(false));
        })
        .catch((err) => {
            dispatch(setLoading(false));
        });
};

export const BarCodeQty = (e, index) => async (dispatch, getState) => {
    let { name, value } = e.target;
    const { po_items } = getState().receiving;
    po_items[index][name] = value;
    dispatch({ type: MANUAL, payload: po_items });
};

export const updateReceiving = (id, formdata) => async (dispatch, getState) => {
    const { item_receipt_template_id } = getState().receiving.receivingData;

    formdata['item_receipt_template_id'] = item_receipt_template_id;
    // console.log(formdata);
    dispatch(setLoading(true));
    ReceivingService.updateReceiving(id, formdata)
        .then((res) => {
            if (res.data.status == 0) {
                ToastDanger(res.data.errors);
            } else if (res.data.status == 1) {
                // console.log("formdata.actions",formdata.actions);
                ToastSuccess(res.data.message);
                dispatch({ type: MANUAL, payload: [] });
                dispatch({ type: GET_PO, payload: [] });
                dispatch({
                    type: RECEIVING_INPUT_CHANGE,
                    payload: { reference_no: '', remarks: '' },
                });

                if (formdata.actions == 'print') {
                    settoPrint(true, res.data.id, 'Recieving');
                }
                dispatch(receivingRedirect(true));
            }
        })
        .catch((err) => {
            if (err.data.errors.length > 0) {
                ToastDanger(err.data.errors);
            } else {
                ToastDanger(`Failed to update invoice, Server error`);
            }
            console.log(err);
        });
};

export const deleteReceving = (id) => async (dispatch) => {
    /**THIS FUNCTION IS NOT IN USE  */
    // ReceivingService.archivesReceiving(id).then(res => {
    //     ToastSuccess(res.data.message);
    //     window.location.reload();
    // }).catch(err => {
    //     ToastDanger(err.data.message);
    // })
};

export const loadUnReceived = (id, callback) => async (dispatch) => {
    ReceivingService.getUnReceiving(id)
        .then((res) => {
            // console.log("res.data.items",res.data.items);
            // var item_data = res.data.items;
            var item_data = res.data.items;
            var items = [];
            item_data.map((data, index) => {
                if (data.received_qty < data.order_qty) {
                    items.push({
                        lot_no: data.lot_no,
                        sku: data.barcode,
                        product_name: data.product_name,
                        order_qty: data.order_qty,
                        product_unit: data.product_unit
                            ? data.product_unit
                            : '',
                        cost: data.cost,
                        received_qty_old: data.received_qty,
                        srp: data.srp,
                        product_id: data.product_id.toString(),
                        received_qty: 0,
                        brand: data.brand,
                        description: data.description,
                    });
                }
            });
            // console.log("items", items);
            dispatch({
                type: LOAD_UNRECEIVE_DATA,
                payload: { unreceive_items: items },
            });
            callback(items);
        })
        .catch((err) => {
            if (err.data !== undefined) {
                ToastDanger(err.data.message);
            }

            console.log('err', err);
        });
};

export const updateUnReceiving = (formdata) => async (dispatch, getState) => {
    // console.log(formdata)
    dispatch(setLoading(true));
    ReceivingService.submiUnReceiving(formdata)
        .then((res) => {
            ToastSuccess(res.data.message);
            window.location.reload();
        })
        .catch((err) => {
            if (err.data.errors.length > 0) {
                ToastDanger(err.data.errors);
            } else {
                ToastDanger('Failed to saved changes, Server Error');
            }
        });
};

export const redirectTo = (id) => async (dispatch) => {
    window.location.href = '/receivings/edit/' + id;
};

export const checkBoxManual = (e) => async (dispatch, getState) => {
    let checked = e.target.checked;
    let { po_items } = getState().receiving;
    // console.log("po_items", po_items);
    let arr = [];
    if (!checked) {
        arr = [];
    }
    if (po_items.length == 0) {
        dispatch({
            type: MANUAL_ITEM,
            payload: { items: [], supplier_name: '' },
        });
        dispatch({ type: BOOLEAN_VAR, payload: checked });
    } else {
        SwalWarning(
            'Warning!',
            `Selected item(s) will be overwritten, continue?`,
            () => {
                dispatch({ type: BOOLEAN_VAR, payload: checked });
                dispatch({
                    type: MANUAL_ITEM,
                    payload: { items: [], supplier_name: '' },
                });
            },
            console.log('cancelled')
        );
    }

    // dispatch({type : BOOLEAN_VAR, payload : e.target.checked})
};

export const load_supplier = () => async (dispatch) => {
    ReceivingService.getSupplier()
        .then((result) => {
            // console.log(result);
        })
        .catch((err) => {});
};

// get all the select options
export const getSupplier = () => async (dispatch) => {
    ReceivingService.getSupplier()
        .then((res) => {
            let createOption = createSelectOption(res.data.suppliers);
            dispatch({ type: GET_PO_SUPPLIERS_DEFAULT, payload: createOption });
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                ToastDanger(error.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            console.log(error);
        });
};

// export const SearchSupplier = (inputValue, callback) => async (dispatch) => {
//     dispatch(setLoading(true));

//     const formdata = {
//         search: inputValue,
//     };
//     // const timer = setTimeout(() => {
//     // console.log(formdata);
//     // MODIFIED TO CANCEL PREVIOuS REQUEST PER SEARCH - ASK LUKE FOR ASSISTANCE
//     if (inputValue.length >= 3) {
//         return ReceivingService.getSupplier(formdata)
//             .then((res) => {
//                 let createOption = createSelectOption(res.data.suppliers);
//                 // dispatch({ type: GET_PO_SUPPLIERS_DEFAULT, payload: createOption });
//                 dispatch({ type: GET_SUPPLIERS, payload: createOption });
//                 dispatch(setLoading(false));
//                 callback(createOption);
//                 // console.log("createOption", createOption);
//                 return createOption;
//             })
//             .catch((err) => {
//                 ToastDanger('Failed to load supplier');
//             });
//     }
//     // }, 500);
// };

export const SearchSupplier = (search) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const formdata = {
            search: search,
        };

        // MODIFIED TO CANCEL PREVIOuS REQUEST PER SEARCH - ASK LUKE FOR ASSISTANCE
        ReceivingService.getSupplier(formdata)
            .then((res) => {
                let supplier_arr = [];
                let suppliers = res.data.suppliers;

                suppliers.map((val, i) => {
                    let select = {
                        value: val._id,
                        label: val.name,
                        name: 'supplier_id',
                    };
                    supplier_arr[i] = select;
                });
                resolve(supplier_arr);
                // dispatch({ type: GET_PO_SUPPLIERS, payload: supplier_arr });
            })
            // .then((res) => {
            //     // console.log(res.data.suppliers.data);
            //     let createOption = createSelectOption(res.data.suppliers.data);
            //     dispatch({ type: GET_PO_SUPPLIERS, payload: createOption });
            //     dispatch(setLoading(false));
            //     callback(createOption);
            //     return createOption;
            // })
            .catch((err) => {
                // dispatch(setLoading(false));
                console.log(err);
                reject(err);
            });
    });

export const handleSelectSupplier =
    (val, callback) => async (dispatch, getState) => {
        if (val) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        // console.log(selectOption.value);
        // dispatch({ type: HANDLE_PO_SELECT_SUPPLIER, payload: val });
        // dispatch(getDisplaySupplier(selectOption.value));
        let { PO } = getState().receiving;
        PO.supplier_id = val.value;
        dispatch({ type: SET_PO, payload: PO });
        dispatch({ type: SUPPLIER_NAME, payload: val.label });
        dispatch(getItemSupplier(val.value));
    };

// get the supplier's items
const getItemSupplier = (id, sup_name) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    ReceivingService.getItemSupplier(id)
        .then((res) => {
            dispatch(setLoading(false));
            let items = res.data.products;

            if (!items) {
                ToastWarning(
                    'Supplier does not have item in the system. please add at least one item.'
                );

                let po_items = [];
                dispatch({ type: MANUAL, payload: po_items });
            } else {
                let items_arr = [];
                items = [];
                // console.log("items", items);
                items.map((val, i) => {
                    // console.log("map val", val);

                    if (val.product !== null) {
                        let attribute = '';
                        try {
                            if (
                                val.product.attribute_string == '()' ||
                                val.product.attribute_string == null ||
                                val.product.attribute_string == ''
                            ) {
                                attribute = '';
                            } else {
                                let attr_replace_char =
                                    val.product.attribute_string
                                        .replace('(', '')
                                        .replace(')', '');
                                attribute =
                                    attr_replace_char.slice(0, 40) +
                                    (attr_replace_char.length > 40
                                        ? '...'
                                        : '');
                            }
                        } catch (err) {}

                        let lot_no = '';
                        try {
                            lot_no = val.product.single_lot.lot_no;
                        } catch (err) {}

                        // console.log('VAL:', val);
                        let select = {
                            _id: val.product_id ? val.product._id : null,
                            item_id: val.product_id ? val.product._id : null,
                            value: val.product_id,
                            label:
                                val.product != null
                                    ? val.product.product_name
                                    : '',
                            barcode:
                                val.product != null ? val.product.barcode : '',
                            brand: val.product != null ? val.product.brand : '',
                            product_name:
                                val.product != null
                                    ? val.product.product_name
                                    : '',
                            sku: val.product != null ? val.product.sku : '',
                            qty: 1,
                            product_unit_id:
                                val.product != null
                                    ? val.product.product_unit_id
                                        ? val.product.product_unit_id
                                        : null
                                    : '',
                            product_unit_name:
                                val.product != null
                                    ? val.product.product_unit
                                        ? val.product.product_unit.name
                                        : ''
                                    : '',
                            attribute_string: attribute,
                            cost: val.cost,
                            amount: val.cost,
                            srp: val.product != null ? val.product.srp : '',
                            description:
                                val.product != null
                                    ? val.product.description
                                    : '',
                            lot_no: lot_no,
                            status_code:
                                val.product != null
                                    ? val.product.status_code
                                    : '',
                            // brand: val.product.brand != null ? val.product.brand.name : "",
                        };

                        // console.log('SELECT:', select);

                        // items_arr[i] = select;
                        items_arr.push(select);
                    }
                });

                // console.log(items_arr,"items_arr");
                dispatch({ type: RECEIVING_LOAD_ITEM_SUP, payload: items_arr });

                let item_data = [
                    {
                        sku: '',
                        product_name: '',
                        order_qty: 0,
                        product_unit: '',
                        cost: 0,
                        received_qty: 0,
                        srp: 0,
                        item_id: '',
                        lot_no: '',
                        expiry_date: '',
                        item_id: '',
                        items: items_arr,
                    },
                ];

                dispatch({ type: MANUAL, payload: item_data });
                // callback(item_data);
            }
        })
        .catch((error) => {
            ToastDanger('Network error, please reload the page.');
            console.log(error);
        });
};

export const searchProduct =
    (search, index, new_item_template) => (dispatch, getState) =>
        new Promise((resolve, reject) => {
            // do something was a success
            let { PO, supplier_item_only } = getState().receiving;

            const formdata = {
                search: search,
                supplier_id: PO.supplier_id,
                supplier_item_only: supplier_item_only,
            };
            ReceivingService.getProducts(formdata)
                .then((res) => {
                    let items_arr = [];
                    let items = res.data.supplierItems;

                    items.forEach((item, i) => {
                        let attribute = '';
                        if (
                            item.attribute_string == '()' ||
                            item.attribute_string == null ||
                            item.attribute_string == ''
                        ) {
                            attribute = '';
                        } else {
                            let attr_replace_char = item.attribute_string
                                .replace('(', '')
                                .replace(')', '');
                            attribute =
                                attr_replace_char.slice(0, 40) +
                                (attr_replace_char.length > 40 ? '...' : '');
                        }

                        let select = {
                            _id: item._id,
                            value: item._id,
                            label: item.product_name,
                            sub_label: attribute,
                            barcode: item.barcode,
                            product_name: item.product_name,
                            sku: item.sku,
                            qty: 1,
                            product_unit_id: item.product_unit_id
                                ? item.product_unit_id
                                : null,
                            product_unit_name: item.product_unit
                                ? item.product_unit.name
                                : '',
                            attribute_string:
                                item.attribute_string == '()'
                                    ? null
                                    : item.attribute_string,
                            cost: item.cost,
                            srp: item.srp,
                            amount: item.cost,
                            description: item.description,
                            lot_no: '',
                            status_code: item.status_code,
                            i: i,
                            description: item.description,
                            brand: item.brand,
                            category: item.category,
                        };

                        items_arr.push(select);
                    });

                    resolve(items_arr);
                })
                .catch((error) => {
                    if (error.data.errors.length > 0) {
                        ToastDanger(error.data.errors);
                    } else {
                        ToastDanger('Network error, please reload the page.');
                    }
                    console.log(error);
                    reject();
                });

            // do something was a fail
        });

// create selectOptions use in suppliers
const createSelectOption = (lists) => {
    let selectOptions = [];

    lists.map((val, i) => {
        let select = { value: val._id, label: val.name, i: i };

        selectOptions[i] = select;
    });

    return selectOptions;
};

export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getReceiving(null, null, pageOrigin));
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    // dispatch(getReceiving(1));
};

// setmodal
export const setModal =
    (modal, id = null, status) =>
    async (dispatch) => {
        switch (modal) {
            case 'supplier_modal':
                dispatch({
                    type: SET_MODAL,
                    payload: { modal: modal, status: status },
                });
                break;
            case 'print_modal':
                dispatch({
                    type: SET_MODAL,
                    payload: { modal: modal, status: status },
                });
                settoPrint(false, null, 'Recieving');

                if (status === false) {
                    dispatch(reClearUpload());
                    return;
                }
                dispatch(viewReceiving(id));
                break;
            case 'barcode_modal':
                dispatch(viewReceiving(id));
                dispatch({
                    type: SET_MODAL,
                    payload: { modal: modal, status: status },
                });
            default:
                break;
        }
    };

// clear upload
export const reClearUpload = () => async (dispatch) =>
    dispatch({ type: RECEIVING_CLEAR_UPLOAD });

export const add_items = () => async (dispatch, getState) => {
    let { po_items, supp_items } = getState().receiving;
    let item_data = {
        sku: '',
        product_name: '',
        order_qty: 0,
        product_unit: '',
        cost: 0,
        received_qty: 0,
        srp: 0,
        item_id: '',
        lot_no: '',
        expiry_date: '',
        item_id: '',
        items: supp_items,
    };
    po_items.push(item_data);

    dispatch({ type: MANUAL, payload: po_items });

    let bool = false;
    po_items.map((data, key) => {
        if (data.cost <= 0 || data.srp <= 0 || data.order_qty <= 0) {
            bool = true;
        }
    });
    dispatch({ type: BTN_SUB, payload: bool });
};

export const removeitems = (index) => async (dispatch, getState) => {
    let { po_items } = getState().receiving;
    let _po_items = po_items.filter((_, i) => i !== index);
    dispatch({ type: MANUAL, payload: _po_items });
};

export const expiryDate = (date, key) => async (dispatch, getState) => {
    if (date) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { po_items } = getState().receiving;
    po_items[key]['expiry_date'] = date;
    dispatch({ type: MANUAL, payload: po_items });
};

export const dynamicInputChange = (e, index) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { name, value } = e.target;
    let { po_items } = getState().receiving;
    if (name == 'cost' || name == 'srp') {
        if (value == '' || value <= 0) {
            value = po_items[index]['org_' + name];
        }
    }
    if (name == 'order_qty') {
        if (value <= 0) {
            value = 1;
        }
    }
    if (name == 'received_qty') {
        if (value < 0) {
            value = 1;
        }
    }

    po_items[index][name] = value;
    dispatch({ type: MANUAL, payload: po_items });

    let bool = false;
    po_items.map((data, key) => {
        if (data.cost <= 0 || data.srp <= 0 || data.order_qty <= 0) {
            bool = true;
        }
    });
    dispatch({ type: BTN_SUB, payload: bool });
};

export const dynamicSelect = (val, key) => async (dispatch, getState) => {
    let { po_items, supp_items } = getState().receiving;

    // Check if the product is already selected in other rows
    const isProductAlreadySelected = po_items.some(
        (item, i) => i !== key && item.product_id === val._id
    );

    if (isProductAlreadySelected) {
        // Display an error message (You can replace this with your preferred way of showing errors)
        ToastDanger('Product is already added on the list');
        // Remove the row
        dispatch(removeitems(key));
    } else {
        po_items[key]['product_name'] = val.product_name;
        po_items[key]['product_unit_name'] = val.product_unit_name;
        po_items[key]['product_unit_id'] = val.product_unit_id;
        po_items[key]['item_id'] = val._id;
        po_items[key]['product_id'] = val._id;
        po_items[key]['cost'] = val.cost;
        po_items[key]['srp'] = val.srp;
        po_items[key]['org_cost'] = val.cost;
        po_items[key]['org_srp'] = val.srp;
        po_items[key]['status_code'] = val.status_code;
        po_items[key]['selected'] = true;
        po_items[key]['barcode'] = val.barcode;
        po_items[key]['sku'] = val.sku;
        po_items[key]['description'] = val.description;
        po_items[key]['brand'] = val.brand;
        po_items[key]['category'] = val.category;
        po_items[key]['productDetails'] = [];

        dispatch({ type: MANUAL, payload: po_items });
    }
};

export const getSingleProduct =
    (id, status = null) =>
    async (dispatch) => {
        dispatch(setLoading(false));

        ReceivingService.getSingleProduct(id)
            .then((res) => {
                if (res.data.status == 1) {
                    // console.log(res.data.product);
                    let product = res.data.docs;

                    let fetchData = {
                        _id: product._id,
                        total_stocks: product.total_stocks,
                        last_arrival: product.last_arrival,
                        incoming_orders: product.incoming_orders,
                    };

                    dispatch({
                        type: HOVER_PRODUCT,
                        payload: {
                            productId: id,
                            productDetails: fetchData,
                        },
                    });

                    dispatch(setLoading(false));

                    // if (status == 'delete') {
                    //     dispatch(archive());
                    // }
                }
            })
            .catch((error) => {
                dispatch(setLoading(false));
                ToastDanger('Network error, please reload the page.');
                $('.btn-save').removeAttr('disabled').html('Save');
                console.log(error);
            });
    };

export const ShowComboBox = (key) => async (dispatch, getState) => {
    let { po_items } = getState().receiving;
    po_items[key]['selected'] = false;
    dispatch({ type: MANUAL, payload: po_items });
};

// handle prev, next page of modals
export const goPage = (direction) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    let { receivingData } = getState().receiving;

    let params = { direction, _id: receivingData._id };

    try {
        const go_to = await ReceivingService.goPage(params);
        dispatch(viewReceiving(go_to.data._id));
    } catch (e) {
        console.log(e);
        ToastDanger('Server Error');
        dispatch(setLoading(false));
    }
};

export const editCost = (editable_cost) => async (dispatch, getState) => {
    let cost = editable_cost ? false : true;
    dispatch({ type: EDIT_COST, payload: cost });
};
export const editSrp = (editable_srp) => async (dispatch, getState) => {
    let srp = editable_srp ? false : true;
    dispatch({ type: EDIT_SRP, payload: srp });
};

export const searchItem = (inputValue, key) => async (dispatch, getState) => {
    // console.log("sup_id", sup_id);
    let { PO, po_items } = getState().receiving;
    // console.log("PO", PO.supplier_id);
    // dispatch(setLoading(true));
    const formdata = {
        search: inputValue,
        supplier_id: PO.supplier_id,
    };

    if (inputValue.length >= 3) {
        return ReceivingService.searchItem(formdata)
            .then((res) => {
                //    console.log("res", res);
            })
            .catch((err) => {
                ToastDanger('Failed to load supplier');
            });
    }
};

export const sendEmail = (e, id) => async (dispatch, getState) => {
    let { email_config } = getState().receiving;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendSingleEmail(id));
        });
    } else {
        dispatch(sendSingleEmail(id));
    }
};

export const sendSingleEmail = (id) => async (dispatch, getState) => {
    dispatch({ type: SEND_EMAIL, payload: true });
    let { receivingData } = getState().receiving;
    ReceivingService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: true });
        })
        .catch((err) => {
            // console.log("err", err);
            // ToastDanger('Failed to send email.');
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: receivingData.email_sent });
        });
};

export const reSendEmail = (e, id) => async (dispatch, getState) => {
    SwalWarning(
        'Are you sure?',
        'Do you really want to resend this email?',
        () => dispatch(sendEmail(e, id))
    );
};

export const getPdf = (e, id, lot_no) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_BTN, payload: true });
    ReceivingService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = lot_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            dispatch({ type: DOWNLOAD_BTN, payload: false });
        })

        .catch((err) => {
            dispatch({ type: DOWNLOAD_BTN, payload: false });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const emailConfig = () => async (dispatch) => {
    let x = 0;
    while (x < 3) {
        try {
            const result = await ReceivingService.getEmailConfig();
            dispatch({ type: EMAIL_SETTING_DATA, payload: result.data });
            x = 4;
        } catch (err) {
            // Temporary comment coz of connection timeout
            // ToastDanger('Failed to load email configuration.');
            console.log('error', err);
            x++;
        }
    }
};

const verfiyPass = (callback) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
        };
        ReceivingService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(result.data.message, callback('success'));
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const receivingRedirect =
    (status = false) =>
    async (dispatch) => {
        dispatch({ type: RECEIVING_REDIRECT, payload: status });
    };

export const getReceivingTemplates = () => async (dispatch) => {
    try {
        const res = await ReceivingService.getReceivingTemplates();
        const receiving_templates = res.data.item_receipt_templates;

        let ir_default_template = {
            value: res.data.selected_template._id,
            label: res.data.selected_template.template_name,
        };
        let item_receipt_templates = [];
        let item_receipt_template_id = res.data.selected_template._id;

        receiving_templates.map((temp, i) => {
            let select = {
                value: temp._id,
                label: temp.template_name,
                name: 'item_receipt_template',
            };
            item_receipt_templates[i] = select;
        });

        let payload = {
            item_receipt_templates,
            ir_default_template,
            item_receipt_template_id,
        };

        dispatch({ type: GET_RECEIVING_TEMPLATES, payload });
    } catch (error) {
        // console.log(error.console);
        // ToastDanger(error.message);
        console.log(error);
        ToastDanger('Failed to load templates');
    }
};

export const handleSelectTemplate = (selectedOption) => async (dispatch) => {
    if (selectedOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let payload = {
        ir_default_template: selectedOption,
        item_receipt_template_id: selectedOption.value,
    };

    dispatch({ type: SELECT_RECEIVING_TEMPLATE, payload });
};

export const dynamiPdf = (e, id, index) => async (dispatch, getState) => {
    let { receivings } = getState().receiving;
    let rv = receivings[index];
    rv.download_pdf = true;
    receivings[index] = rv;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: receivings });
    ReceivingService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = rv.lot_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            rv.download_pdf = false;
            receivings[index] = rv;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: receivings });
        })

        .catch((err) => {
            rv.download_pdf = false;
            receivings[index] = rv;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: receivings });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const sendEmailDyanmic =
    (e, id, index) => async (dispatch, getState) => {
        let { receivings } = getState().receiving;
        let rv = receivings[index];

        if (rv.email_sent) {
            SwalWarning(
                'Are you sure?',
                'Do you really want to resend this email?',
                () => dispatch(sendByIndex(id, index))
            );
        } else {
            dispatch(sendByIndex(id, index));
        }
    };

const sendByIndex = (id, index) => async (dispatch, getState) => {
    let { email_config } = getState().receiving;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendEmailIndex(id, index));
        });
    } else {
        dispatch(sendEmailIndex(id, index));
    }
};

const sendEmailIndex = (id, index) => async (dispatch, getState) => {
    let { receivings } = getState().receiving;
    let rv = receivings[index];
    rv.sending_email = true;
    receivings[index] = rv;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: receivings });
    ReceivingService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            rv.sending_email = false;
            rv.email_sent = true;
            receivings[index] = rv;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: receivings });
        })
        .catch((err) => {
            // ToastDanger('Failed to send email.');
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            rv.sending_email = false;
            receivings[index] = rv;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: receivings });
        });
};

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });

        // console.log(newArray);
        dispatch({ type: GET_IR_ROWS_TO_DELETE, payload: newArray });
    };

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    let { rows_to_delete } = getState().receiving;
    let ir_no = [];
    rows_to_delete.map((rows, i) => {
        ir_no.push('<b>' + rows.name + '</b>');
    });
    SwalWarningHtml(
        'Warning!',
        `Do you want to remove the Item Receipt(s): ${ir_no.join(', ')}?`,
        () => dispatch(archiveList())
    );
};

export const archiveList =
    (pageOrigin = null) =>
    async (dispatch, getState) => {
        let { rows_to_delete } = getState().receiving;
        let id_arr = [];

        rows_to_delete.map((row) => {
            id_arr.push(row.id);
        });
        let params = {
            ids: JSON.stringify(id_arr),
        };

        // console.log(params)

        try {
            const res = await ReceivingService.archieveList(params);

            switch (res.data.status) {
                case 0:
                    ToastWarning(res.data.message);
                    break;
                case 1:
                    ToastSuccess(res.data.message);
                    dispatch(getReceiving());
                default:
                    break;
            }
        } catch (error) {
            ToastDanger('Server Error');
            console.log(error);
        }
    };

export const clear_data = () => async (dispatch) => {
    dispatch({ type: CLEAR_DATA });
};

export const donwloadBarcode = () => async (dispatch, getState) => {
    const { po_items, receivingData } = getState().receiving;
    // console.log("po_items", po_items);
    let exceldata = [];
    po_items.map((item) => {
        for (let i = 1; i <= item.barcode_qty; i++) {
            exceldata.push({
                Name: item.product_name,
                Brand: item.brand,
                SRP: item.srp,
                COST: item.cost,
                Details: item.description,
                SKU: item.sku,
                BARCODE: item.barcode,
            });
        }
    });
    // const apiData = [{ 'foo test': 'foo' }, { bar: 'bar' }]
    const fileName = receivingData.lot_no;
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(exceldata);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export const cancelReceiving = () => async (dispatch, getState) => {
    let { receivingData } = getState().receiving;
    // console.log(receivingData);
    SwalWarningHtml(
        'Are You Sure?',
        'You are about to cancel Item Receipt Continue?',
        () => dispatch(passwordCancel())
    );
};

const passwordCancel = () => async (dispatch) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
            role_id: getUserData().role_id,
            subscriber_id: getUserData().subscriber_id,
            id: getUserData()._id,
        };
        ReceivingService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(
                    result.data.message,
                    dispatch(submitCancel())
                );
            })
            .catch((err) => {
                console.log('err', err);
                if (err.data.errors.length > 0) {
                    SwalError(err.data.message);
                } else {
                    console.log(err);
                    SwalError('Process is failed, server error');
                }
                // reject(err)
            });
    });
};

export const submitCancel = () => async (dispatch, getState) => {
    let { receivingData, btn_form } = getState().receiving;
    btn_form.cancel = true;
    dispatch({ type: BTN_FORM, payload: btn_form });
    ReceivingService.cancelReceiving(receivingData._id)
        .then((result) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            ToastSuccess(
                'Item Receipt: ' + receivingData.lot_no + ' cancelled'
            );
            // dispatch(receivingRedirect(false));
            window.location.href = '/receivings';
        })
        .catch((err) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            if (err.data.errors.length > 0) {
                ToastDanger(err.data.errors);
            } else {
                ToastDanger('Failed to cancel Invoice');
            }
        });
};

export const autoPrint = () => async (dispatch) => {
    let toPrint = JSON.parse(gettoPrint('Recieving'));
    if (toPrint !== null) {
        try {
            if (toPrint.print) {
                // console.log("printing...");
                dispatch(setModal('print_modal', toPrint.id, true));
            }
        } catch (err) {}
    }
};
