import { SET_LOADING, GET_TERMS_CONDITIONS, TERM_CONDITION_INPUT_CHANGE, GET_SINGLE_TERM_CONDITION,
     SET_PAGE, CLEAR_TERM_CONDITION, SEARCH_INPUT, TERMS_TOGGLE_LIST, SORT_BY_COLUMN, GET_ROWS_TO_DELETE, SET_ROW_CHECKED } from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";
import { TermConditionService } from './_service.term_condition';
import { SwalWarning } from '../../../Services/_swal.service';
import { getTermsConditions as getTermsConditionsSO, setModal as setModalSO, handleSelectTermsConditions as handleSelectTermsConditionsSO } from '../../actions/sales_order/sales_order.actions';
import { getPaymentTerms as getPaymentTermsPO, setModal as setModalPO, handleSelectPaymentTerms as handleSelectPaymentTermsPO } from '../../actions/purchase_order/purchase_order.actions';
import { getTermsConditions as getTermsConditionsQU, setModal as setModalQU, handleSelectTermsConditions as handleSelectTermsConditionsQU } from '../../actions/quotation/quotation.actions';
import { getPaymentTerms as getPaymentTermsSettingApp, getSettingApp, setModal as setModalSettingApp } from '../../actions/settings_app/setting_app.actions';
import { getNumRows } from '../../../Utils/Common';

// handle inputs
export const handleInputChange = e => async dispatch => {
    dispatch({ type: TERM_CONDITION_INPUT_CHANGE, payload: { key: e.target.name, value: e.target.value }})
}

// handle edit inputs
export const handleSearchInput = e => async dispatch => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value })
}

// loading
export const setLoading = status => async dispatch => dispatch({ type: SET_LOADING, payload: status });

// clear data
export const clearTermCondition = () => async dispatch => dispatch({ type: CLEAR_TERM_CONDITION  });


// set page
export const setPage = (page, id, status = true) => async (dispatch, getState) => {

    let { edit_url } = getState().term_condition;

    switch (page) {
        case 'main_page':
            dispatch({ type: SET_PAGE, payload: { page, status } });
            dispatch(clearTermCondition());
            break;
        case 'edit_page':
            let edit_url_with_id = `${edit_url}/${id}`;
            dispatch({ type: SET_PAGE, payload: { page, status, edit_url: edit_url_with_id } });
            break;
        case 'delete_modal':
            dispatch(getSingleTermCondition(id, 'delete'));
            break;
        default:
            break;
    }
}

//  fetch terms and conditions
export const getTermsConditions = (pageNumber,  rows_per_page = null) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    let { search , pagination, sort_order_name, sort_order_direction } = getState().term_condition; 
    let rows_per_page_val = rows_per_page !== null ? rows_per_page : getNumRows('term_condition');

    let searchPostParams = {
        search,
        activePage: pagination.activePage,
        page: pageNumber,
        rows_per_page: rows_per_page_val, 
        sort_order_name: sort_order_name,
        sort_order_direction: sort_order_direction
    }

    TermConditionService.paginateWithSearchTermCondition(searchPostParams).then(res => {

        let pagination = {
            totalCount: res.data.terms_conditions.total, 
            activePage: res.data.terms_conditions.current_page, 
            itemsCountPerPage: res.data.terms_conditions.per_page, 
            totalItemsCount: res.data.terms_conditions.total,
        }

        dispatch({ type: GET_TERMS_CONDITIONS, payload: { terms_conditions: res.data.terms_conditions.data, pagination, } })
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
        console.log(err);
    });
}

// fetch single term condition
export const getSingleTermCondition = (id) => async dispatch => {
    TermConditionService.getSingleTermCondition(id).then(res => {
        dispatch({ type: GET_SINGLE_TERM_CONDITION, payload: res.data.term_condition });
        dispatch(toggleDrawer(false, 'edit_modal'));
    })
    .catch(error => {
        ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
}

// store 
export const termConditionPost = (e, pageOrigin) => async (dispatch, getState) => {
    e.preventDefault();
    let { single_term_condition } = getState().term_condition;
    $('.btn-save').attr('disabled','disabled').html('Saving...');
    
    let arrOrigin = ['purchase_order','quotation','sales_invoice'];
    if(arrOrigin.includes(pageOrigin)){
         // to get the return selected value 
        single_term_condition['select_payment_terms'] = true;
    }

    TermConditionService.TermConditionPost(single_term_condition).then(res => {
        switch(res.data.status){
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:

                ToastSuccess(res.data.message);
                dispatch(clearTermCondition());
                const selectedTerms = res.data.select_payment_terms ? { value: res.data.select_payment_terms._id, label: res.data.select_payment_terms.title } : {};

                if(pageOrigin == 'payment_terms')
                {
                    dispatch(clearTermCondition());
                    dispatch(setPage('main_page'));
                }

                if(pageOrigin == 'sales_order')
                {
                    dispatch(getTermsConditionsSO());
                    dispatch(handleSelectTermsConditionsSO(selectedTerms));
                    dispatch(setModalSO('payment_terms_modal', false));
                }

                if(pageOrigin == 'purchase_order')
                {
                    dispatch(getPaymentTermsPO());
                    dispatch(handleSelectPaymentTermsPO(selectedTerms));
                    dispatch(setModalPO('payment_terms_modal', false));
                }
                
                if(pageOrigin == 'quotation')
                {
                    dispatch(setModalQU('payment_terms_modal', false));
                    dispatch(getTermsConditionsQU());
                    dispatch(handleSelectTermsConditionsQU(selectedTerms));
                }

                if(pageOrigin == 'setting_app')
                {
                    dispatch(getPaymentTermsSettingApp());
                    dispatch(setModalSettingApp('payment_terms_modal', false));
                }

                break;
            default:
                break;
        }
        $('.btn-save').removeAttr('disabled').html('Save');
    })
    .catch(error => {
        if (error.data?.errors.length > 0) {
            ToastDanger(error.data.errors);
        } else {
            ToastDanger('Network error, please reload the page.');
        }
        $('.btn-save').removeAttr('disabled').html('Save');
        console.log(error);
    });
}	



// update 
export const updateSingleTermCondition = (e , pageOrigin = null) => async (dispatch, getState) => {
    e.preventDefault();
    
    let { single_term_condition } = getState().term_condition;

    $('.btn-update').attr('disabled','disabled').html('Updating...');
    TermConditionService.updateSingleTermCondition(single_term_condition, single_term_condition._id).then(res => {	

        switch (res.data.status) {
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:
                // dispatch(setPage('main_page'));
                ToastSuccess(res.data.message);
                dispatch(getTermsConditions());
                if(pageOrigin == 'setting_app')
                {
                    dispatch(getPaymentTermsSettingApp());
                    dispatch(setModalSettingApp('edit_modal', false));
                }
                break;
            default:
                break;
        }
        $('.btn-update').removeAttr('disabled').html('Update');
    })
    .catch(error => {
        ToastDanger('Network error, please reload the page.');
        $('.btn-update').removeAttr('disabled').html('Update');
        console.log(error);
    });
};



// Toggle Terms  list
export const toggleDrawer = (open, pageOrigin = null, cancel = null) => async (dispatch, getState) => {
    
    let { toggle_list } = getState().term_condition;
    toggle_list['open'] = open;

    switch (pageOrigin) {
        case 'setting_app':
            if(open){
                dispatch(setModalSettingApp('payment_terms_modal', false));
            }else{
                dispatch(setModalSettingApp('payment_terms_modal'));
            }
            break;
        case 'edit_modal':
            if(open){

                dispatch(setModalSettingApp('edit_modal', false));
                if(cancel){
                    dispatch(clearTermCondition());
                }else{
                    dispatch(getSettingApp());
                    dispatch(clearTermCondition());
                }

            }else{
                dispatch(setModalSettingApp('edit_modal'));
            }
            break;
        default:
            break;
    }

    dispatch({ type: TERMS_TOGGLE_LIST, payload: toggle_list });

}


export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value })
}

// Sort by column name and direction
export const sortTableByColumn = (sort_order_name, sort_order_direction) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getTermsConditions());
}


// returns selected rows only 
export const getSelectedRows = (selectedRows, currentRows) => async dispatch => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current,i) => {
        selectedRows.map((selected, _i) => {
            if(selected.index == current.index)
            {
                let selected = { id: current.data[0], name: current.data[1] };
                newArray.push(selected);
            }
        })
    });
    // console.log(newArray);
    dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
}


export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    
    let { rows_to_delete } = getState().term_condition;
    SwalWarning('Warning!', `Do you want to remove the payment terms?`, () => dispatch(moveToArchive()));
}

// set the rows checked box
export const setRowChecked = () => async dispatch => dispatch({type: SET_ROW_CHECKED}); 


// archive
export const moveToArchive = () => async (dispatch, getState) => {

    let { rows_to_delete } = getState().brand;
    let id_arr = [];
    rows_to_delete.map(row => { id_arr.push(row.id) })

    let params = { 
        ids: JSON.stringify(id_arr)
    }

    TermConditionService.ArchiveTermCondition(params).then(res => {
        // dispatch(setRowChecked());
        ToastSuccess(res.data.message);
        dispatch(clearTermCondition());
        dispatch(getTermsConditions());
    })
    .catch(error => {
        ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
};



// export const archive = () => async (dispatch, getState) => {
    
//     let { single_term_condition } = getState().term_condition;

//     SwalWarning('Warning!', `Do you want to remove Terms & Conditions: ${single_term_condition.title}?`, () => dispatch(moveToArchives()));
// }
